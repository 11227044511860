
export { StatPrep };

import { CONFIG } from './config.js';
import { App } from './app.js';

/* jshint -W069 */  // ['xx'] is better written in dot notation

var StatPrep = (function () {

	// references to modules
	var webReport, editData;

	var IS_TOUCH = false;

	var EARNED = [
		'ace',
		'spike', // assist generally implied
		'tip',   // assist generally implied
		'dump',  // assist sometimes implied
		'dbh',   // assist generally implied
		'blck',
		'kill',  // legacy action
		'killo'  // legacy action
	];

	var ERRORS = [
		'srv',
		'rcv',
		'spk',
		'tip',
		'dig',
		'set',
		'dbh',
		'dump',
		'blck',
		'fbp',
		'2br',
		'3br',
		'whb',
		'setin',
		'setout'
	];

	var FAULTS = [
		'fault',
		'net',
		'bhndl',
		'undr',
		'foot',
		'bra',
		'otn',
		'oor',
		'roov', // legacy
		'dblh', // legacy
		'lift'  // legacy
	];

	var IN_RALLY = [
		'1srv',
		'2srv',
		'3srv',
		'xsrv',
		'0pass',
		'1pass',
		'2pass',
		'3pass',
		'xpass',
		'0fbp',
		'1fbp',
		'2fbp',
		'3fbp',
		'xfbp',
		'dgatt',
		'htatt',
		'blksip',
		'fbrip',
		'1setin',
		'2setin',
		'3setin',
		'1setout',
		'2setout',
		'3setout'
	];

	var TOUCH = [
		'touch',
		'settch',
		'srvtch',
		'digtch',
		'blktch',
		'atktch',
		'srtch',
		'fbptch'
	];

	var EDIT = [
		'total_htatt',
		'combined_kill_attempts',
		'digs',
		'block_attempts'
	];

	var actionsEarned = {
		"kill"   : "Kill", // legacy action
		"killo"  : "Kill (Non-Setter Assist)",  // legacy action
		"ace"    : "Ace",
		"spike"  : "Spike",
		"tip"    : "Tip",
		"dump"   : "Dump",
		"dbh"    : "Down Ball Hit",
		"blck"   : "Block",
		"asst"   : "Assist"
	};

	var actionsErrors = {
		"srv"    : "Serve",
		"rcv"    : "Receive",
		"spk"    : "Spike",
		"dig"    : "Dig",
		"tip"    : "Tip",
		"set"    : "Set",
		"dump"   : "Dump",
		"fbp"    : "Free Ball Receive",
		"dbh"    : "Down Ball Hit",
		"2br"    : "2nd Ball Return",
		"blck"   : "Block",
		"3br"    : "3rd Ball Return",
		"whb"    : "Whose Ball?",
		"setin"  : "Set (In-System)",
		"setout" : "Set (Out-of-System)"
	};

	var actionsFaults = {
		"fault" : "Fault (General)",
		"net"   : "Net",
		"bhndl" : "Ball Handling",
		"undr"  : "Under",
		"otn"   : "Over the Net",
		"foot"  : "Foot Fault",
		"oor"   : "Out of Rotation",
		"bra"   : "Back Row Attack"
	};

	var actionsInRally = {
		"0pass"   : "Over Pass in Play",
		"1pass"   : "1-Pass",
		"2pass"   : "2-Pass",
		"3pass"   : "3-Pass",
		"xpass"   : "Pass (Unrated)",
		"0fbp"    : "Over Pass in Play",
		"1fbp"    : "1-FB Pass",
		"2fbp"    : "2-FB Pass",
		"3fbp"    : "3-FB Pass",
		"xfbp"    : "FB Pass (Unrated)",
		"1srv"    : "1-Serve",
		"2srv"    : "2-Serve",
		"3srv"    : "3-Serve",
		"xsrv"    : "Serve (Unrated)",
		"dgatt"   : "Dig",
		"htatt"   : "Hit Still In Play",
		"blksip"  : "Blocks Still In Play",
		"fbrip"   : "FB Return In Play",
		"1setin"  : "1-Set (In-System)",
		"2setin"  : "2-Set (In-System)",
		"3setin"  : "3-Set (In-System)",
		"1setout" : "1-Set (Out-of-System)",
		"2setout" : "2-Set (Out-of-System)",
		"3setout" : "3-Set (Out-of-System)"
	};

	var actionsTouch = {
		"touch"  : "Touch",
		"settch" : "Set Touch",
		"srvtch" : "Serve Touch",
		"digtch" : "Dig Touch",
		"blktch" : "Block Touch",
		"atktch" : "Attack Touch",
		"srtch"  : "Serve Receive Touch",
		"fbptch" : "Free Ball Pass Touch"
	};

	var init = function (opts) {

		if (opts && opts.webReport) {
			webReport = opts.webReport;
		}
		if (opts && opts.editData) {
			editData = opts.editData;
		}
		if (opts && opts.touch !== undefined) {
			IS_TOUCH = opts.touch || false;
		}
	};

	// aka actionIsExitStat
	var actionResultsInPoint = function (action) {

		if (IS_TOUCH) {
			return true;
		}

		if (action === 'earned' || action === 'errors' || action === 'faults') {
			return true;
		}
		return false;
	};

	// ---------------------
	// Determing action type
	// ---------------------

	var isActionAttack = function (action, code, ignoreDbh) {

		ignoreDbh = ignoreDbh === undefined ? false : ignoreDbh;

		if (action === 'earned') {
			if (
				(code === 'kill')  ||
				(code === 'killo') ||
				(code === 'tip')   ||
				(code === 'dbh' && (ignoreDbh === false)) ||
				(code === 'spike') ||
				(code === 'dump')
			) {
				return true;
			}
		}
		return false;
	};

	var isActionAttackAttempt = function (action, code, ignoreDbh) {

		ignoreDbh = ignoreDbh === undefined ? false : ignoreDbh;

		if (isActionAttack(action, code, ignoreDbh)) {
			return true;
		}

		if (action === 'errors') {
			if (
				(code === 'spk')  ||
				(code === 'tip')  ||
				(code === 'dbh' && (ignoreDbh === false)) ||
				(code === 'dump')
			) {
				return true;
			}
		}

		if (action === 'faults') {
			if (
				(code === 'bra')
			) {
				return true;
			}
		}

		if (action === 'in_rally') {
			if (
				(code === 'htatt')
			) {
				return true;
			}
		}
		return false;
	};

	var isActionAboveTheNetAttack = function (action, code) {
		// This ignores down ball hits, as the intent here is to track desirable attacks
		if (isActionAttack(action, code, true)) {
			return true;
		}
		return false;
	};

	var isActionAboveTheNetAttackAttempt = function (action, code) {
		// This ignores down ball hits, as the intent here is to track desirable attacks
		if (isActionAttackAttempt(action, code, true)) {
			return true;
		}
		return false;
	};

	var isActionAssisted = function (action, code) {
		if (isActionAttack(action, code)) {
			return true;
		}
		return false;
	};

	// Actions for which if they were successful would have counted as an assist
	var isActionMissedAssist = function (action, code) {

		if (action === 'errors') {
			// Since a 'dump' is usually non-assisted, we will not count them here
			if (
				(code === 'spk') ||
				(code === 'tip') ||
				(code === 'dbh')
			) {
				return true;
			}
		}

		if (action === 'in_rally') {
			if (
				(code === 'htatt')
			) {
				return true;
			}
		}

		return false;
	};

	var isActionDig = function (action, code) {

		if (action === 'in_rally') {
			if (code === 'dgatt') {
				return true;
			}
		}

		return false;
	};

	var isActionBlock = function (action, code) {
		if (action === 'earned') {
			if (code === 'blck') {
				return true;
			}
		}
		return false;
	};

	var isActionBlockAttempt = function (action, code) {

		if (isActionBlock(action, code)) {
			return true;
		}

		if (action === 'errors') {
			if (code === 'blck') {
				return true;
			}
		}

		if (action === 'in_rally') {
			if (code === 'blksip') {
				return true;
			}
		}

		return false;
	};

	var isActionPass = function (action, code) {

		if (action === "in_rally") {
			switch (code) {
				case "0pass":
				case "1pass":
				case "2pass":
				case "3pass":
					return true;
			}
		}

		return false;
	};

	var isActionReceive = function (action, code) {

		if (isActionPass(action, code)) {
			return true;
		}

		if (action === 'errors') {
			if (code === 'rcv') {
				return true;
			}
		}

		return false;
	};

	// setting an explicit value for the given Ids
	var setManualValue = function (totals, id, field, gameId, playerId, value) {
		if (!totals[id]['us']['edit'][field]) {
			totals[id]['us']['edit'][field] = {};
		}
		if (!totals[id]['us']['edit'][field][gameId]) {
			totals[id]['us']['edit'][field][gameId] = {};
		}
		var parsedValue = parseInt(value, 10);
		if (isNaN(parsedValue)) {
			parsedValue = 0;
		}

		totals[id]['us']['edit'][field][gameId][playerId] = parsedValue;
		return totals;
	};

	var processManualEntries = function(totals, dataRow, playerTeam, id, game, playerId, hasEditStats) {

		if (!totals[id]) {
			return totals;
		}

		if (!game) {
			return totals;
		}

		if (!totals[id][playerTeam]) {
			return totals;
		}

		let gameId = game.id;

		// A player may not have any actions to pass isActionAttackAttempt
		// but they may still have manual hit entries.  So we must
		// check for those regardless of the action type
		//
		var editHitAttempts = false;
		if (hasEditStats) {
			editHitAttempts = editData.getHitAttemptsForPlayer(game, { num: playerId });
		}

		if (!totals[id][playerTeam]['edit']['combined_kill_attempts_by_game']) {
			totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'] = {};
		}
		if (!totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId]) {
			totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId] = {};
		}

		// If the player in dataRow is the primary player, handle hit attempts
		if (dataRow['player_num'] == playerId) {
			if (!editHitAttempts && isActionAttackAttempt(dataRow['action'], dataRow['code'])) {

				if (!totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId][playerId]) {
					totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId][playerId] = 0;
				}
				totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId][playerId]++;
			}
		}

		var editDigs = false;
		if (hasEditStats) {
			editDigs = editData.getDigAttemptsForPlayer(game, { num: playerId });
		}

		if (!totals[id][playerTeam]['edit']['digs_by_game']) {
			totals[id][playerTeam]['edit']['digs_by_game'] = {};
		}
		if (!totals[id][playerTeam]['edit']['digs_by_game'][gameId]) {
			totals[id][playerTeam]['edit']['digs_by_game'][gameId] = {};
		}

		// If the player in dataRow is the primary player, handle digs
		if (dataRow['player_num'] == playerId) {
			if (!editDigs && isActionDig(dataRow['action'], dataRow['code'])) {
				if (!totals[id][playerTeam]['edit']['digs_by_game'][gameId][playerId]) {
					totals[id][playerTeam]['edit']['digs_by_game'][gameId][playerId] = 0;
				}
				totals[id][playerTeam]['edit']['digs_by_game'][gameId][playerId]++;
			}
		}

		var editBlockAttempts = false;
		if (hasEditStats) {
			editBlockAttempts = editData.getBlockAttemptsForPlayer(game, { num: playerId });
		}

		if (!totals[id][playerTeam]['edit']['block_attempts_by_game']) {
			totals[id][playerTeam]['edit']['block_attempts_by_game'] = {};
		}
		if (!totals[id][playerTeam]['edit']['block_attempts_by_game'][gameId]) {
			totals[id][playerTeam]['edit']['block_attempts_by_game'][gameId] = {};
		}

		// If the player in dataRow is the primary player, handle block attempts
		if (dataRow['player_num'] == playerId) {
			if (editBlockAttempts && isActionBlockAttempt(dataRow['action'], dataRow['code'])) {
				if (!totals[id][playerTeam]['edit']['block_attempts_by_game'][gameId][playerId]) {
					totals[id][playerTeam]['edit']['block_attempts_by_game'][gameId][playerId] = 0;
				}
				totals[id][playerTeam]['edit']['block_attempts_by_game'][gameId][playerId]++;
			}
		}

		return totals;
	};

	// TODO: refactor with processManualEntries
	var processManualEntriesForDerivedKill = function(totals, dataRow, playerTeam, id, game, playerId, hasEditStats) {

		if (!totals[id]) {
			return totals;
		}

		if (!game) {
			return totals;
		}

		let gameId = game.id;

		// A player may not have any actions to pass isActionAttackAttempt
		// but they may still have manual hit entries.  So we must
		// check for those regardless of the action type
		//
		var editHitAttempts = false;
		if (hasEditStats) {
			editHitAttempts = editData.getHitAttemptsForPlayer(game, { num: playerId });
		}

		if (!totals[id][playerTeam]['edit']['combined_kill_attempts_by_game']) {
			totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'] = {};
		}
		if (!totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId]) {
			totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId] = {};
		}

		// If the player in dataRow is the primary player, handle hit attempts
		if (!editHitAttempts) {

			if (!totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId][playerId]) {
				totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId][playerId] = 0;
			}
			totals[id][playerTeam]['edit']['combined_kill_attempts_by_game'][gameId][playerId]++;
		}

		return totals;
	};

	var countKeys = function (obj, ignore) {
		var count = 0;
		for (var key in obj) {
			if (key === ignore) {
				continue;
			}
			if (obj.hasOwnProperty(key)) {
				count++;
			}
		}
		return count;
	};

	var Aggregate = function () {

		var totals = {};
		var agg    = {};
		var grouping;
		var filter;
		var lookup            = {};
		var gameCount         = {};
		var gameAsSetterCount = {};
		var pointsPlayedIn    = {};
		var pointsPlayedInSetterCount = {};
		var pointsPlayedInServerCount = {};
		var pointsPlayedInAsstCount = {};
		var pointsPlayedInLineupCount = {};
		var filterLookup      = {};
		var who = "us";
		var opp = "them";


		var init = function (groupingTmp, filterTmp) {
			grouping = groupingTmp;
			filter   = filterTmp;

			var i, len;
			if (filter && filter.type && filter.list) {
				for (i = 0, len = filter.list.length; i < len; i++) {
					filterLookup[filter.list[i]] = true;
				}
			}


			// initialize all total values (so we can ++)
			var id;
			for (i = 0, len = grouping.list.length; i < len; i++) {
				id = grouping.list[i];
				initGroupingArraysForId(id);
			}
			initGroupingArraysForAgg();

		};

		var getGroupingType = function () {
			return grouping.type;
		};

		var getGrouping = function () {
			return grouping;
		};

		var setPointPlayedIn = function (id, pointCount, position) {

			if (position === 'server') {
				if (!pointsPlayedInServerCount.hasOwnProperty(id)) {
					pointsPlayedInServerCount[id] = {};
				}
				pointsPlayedInServerCount[id][pointCount] = true;

			} else if (position === 'setter') {
				if (!pointsPlayedInSetterCount.hasOwnProperty(id)) {
					pointsPlayedInSetterCount[id] = {};
				}
				pointsPlayedInSetterCount[id][pointCount] = true;

			} else if (position === 'assist') {
				if (!pointsPlayedInAsstCount.hasOwnProperty(id)) {
					pointsPlayedInAsstCount[id] = {};
				}
				pointsPlayedInAsstCount[id][pointCount] = true;

			} else {
				if (!pointsPlayedIn.hasOwnProperty(id)) {
					pointsPlayedIn[id] = {};
				}
				pointsPlayedIn[id][pointCount] = true;
			}
		};

		var setTotal = function (id, key, value) {

			if (!totals[id]) {
				totals[id] = {};
			}

			totals[id][key] = value;
		};

		var add = function (id, key, value) {
			totals[id][key] += value;
			agg[key] += value;
		};

		var inc = function (id, who, key, key2) {

			if (!totals[id]) {
				totals[id] = {};
			}
			if (!totals[id][who]) {
				totals[id][who] = {};
			}
			if (!totals[id][who][key]) {
				if (key2 === undefined) {
					totals[id][who][key] = 0;
				} else {
					totals[id][who][key] = {};
					totals[id][who][key][key2] = {};
				}
			}

			if (key2 !== undefined) {
				totals[id][who][key][key2] ++;
				if (!agg[who][key]) {
					agg[who][key] = {};
				}
				if (!agg[who][key][key2]) {
					agg[who][key][key2] = 0;
				}
				agg[who][key][key2] ++;
			} else {
				totals[id][who][key] ++;
				if (!agg[who][key]) {
					agg[who][key] = 0;
				}
				agg[who][key] ++;
			}
		};

		var initGroupingArrays = function (arg) {

			arg['points_for']                      = 0;
			arg['points_against']                  = 0;
			arg['points_scored_on_serve']          = 0;
			arg['opponent_points_scored_on_serve'] = 0;
			arg['games_played_as_setter']          = 0;
			arg['us_adjustment']                   = 0;
			arg['them_adjustment']                 = 0;

			var usThemArray = ['us', 'them'];
			for (var k=0; k < 2; k++) {
				var usthem = usThemArray[k];

				arg[usthem] = {
					'earned'  : { cnt: 0 },
					'errors'  : { cnt: 0 },
					'faults'  : { cnt: 0 },
					'in_rally': { cnt: 0 },
					'touch'   : { cnt: 0 },
					'edit'    : { cnt: 0 }
				};

				arg[usthem]['blocks']                      = 0;
				arg[usthem]['block_assists']               = 0;
				arg[usthem]['assists']                     = 0;
				arg[usthem]['assists_as_non_setter']       = 0;
				arg[usthem]['assists_as_setter']           = 0;
				arg[usthem]['set_attempts']                = 0;
				arg[usthem]["serve_attempts"]              = 0;
				arg[usthem]["opponent_serve_attempts"]     = 0;
				arg[usthem]["opponent_serve_errors"]       = 0;
				arg[usthem]["opponent_derived_kills"]      = 0; // solely for "us"
				arg[usthem]["opponent_derived_rcv_errors"] = 0; // solely for "us"
				arg[usthem]["htatt_noset"]                 = 0; // solely for "us"
				arg[usthem]["htatt_noset_specified"]       = 0; // solely for "us"
				arg[usthem]["first_ball_side_out"]         = 0; // solely for "us"
				arg[usthem]["kill_to_dig"]                 = 0; // solely for "us"
				arg[usthem]["attack_attempt_to_dig"]       = 0; // solely for "us"

				arg[usthem]["serve_error_detail_out"]      = 0;
				arg[usthem]["serve_error_detail_net"]      = 0;
				arg[usthem]["attack_error_detail_out"]     = 0;
				arg[usthem]["attack_error_detail_net"]     = 0;
				arg[usthem]["attack_error_detail_blocked"] = 0;

				var j, jLen;
				for (j=0, jLen=EARNED.length; j < jLen; j++) {
					arg[usthem]['earned'][EARNED[j]] = 0;
				}
				for (j=0, jLen=ERRORS.length; j < jLen; j++) {
					arg[usthem]['errors'][ERRORS[j]] = 0;
				}
				for (j=0, jLen=FAULTS.length; j < jLen; j++) {
					arg[usthem]['faults'][FAULTS[j]] = 0;
				}
				for (j=0, jLen=IN_RALLY.length; j < jLen; j++) {
					arg[usthem]['in_rally'][IN_RALLY[j]] = 0;
				}
				for (j=0, jLen=TOUCH.length; j < jLen; j++) {
					arg[usthem]['touch'][TOUCH[j]] = 0;
				}
				for (j=0, jLen=EDIT.length; j < jLen; j++) {
					arg[usthem]['edit'][EDIT[j]] = 0;
				}
			}
		};

		var initGroupingArraysForId = function (id) {
			totals[id]            = {};
			lookup[id]            = true;
			gameCount[id]         = {};
			gameAsSetterCount[id] = {};

			pointsPlayedIn[id]            = {};
			pointsPlayedInSetterCount[id] = {};
			pointsPlayedInServerCount[id] = {};
			pointsPlayedInLineupCount[id] = {};
			pointsPlayedInAsstCount[id]   = {};

			initGroupingArrays(totals[id]);
		};

		var initGroupingArraysForAgg = function () {
			initGroupingArrays(agg);
		};

		var addPlayerIfNotExists = function (id, name) {
			App.debugLog("addPlayerIfNotExists - [id:" + id + "] [name: " + name + "]");

			// If we're filtering by player, we don't care about non-listed
			if (isPlayerFilter()) {
				return;
			}

			if (id) {
				// Need to set this for rot_serv_player
				if (grouping.type === "rot_serv_player") {
					var matches = id.match(/(\d)+:(us|them):(\w+)/);
					if (lookup[id]) {
						// check to see if we have previously added without a name
						if (grouping.listNames[matches[3]] === "(unknown)") {
							if (name !== undefined) {
								grouping.listNames[matches[3]] = name;
							}
						}
					} else {
						// completely new player
						initGroupingArraysForId(id);
						if (name !== undefined) {
							grouping.listNames[matches[3]] = name;
						} else {
							grouping.listNames[matches[3]] = "(unknown)";
						}
						grouping.list.push(id);
					}
					setTotal(id, "rot_serv_player", id);
				} else {
					if (lookup[id]) {
						// see if we have an unnamed player
						if (grouping.listNames[id] === "(unknown)") {
							if (name !== undefined) {
								grouping.listNames[id] = name;
							}
						}
					} else {
						// completely new player
						initGroupingArraysForId(id);
						if (name !== undefined) {
							grouping.listNames[id] = name;
						} else {
							grouping.listNames[id] = "(unknown)";
						}
						grouping.list.push(id);
					}
				}

			}

		};

		// According to the stats we want, is this log row relevant?
		var isCorrectLogRow = function (gameId, matchId, playerId, setterId, serverId, asstId, rotServId, rotationId) {
			// If we're using a secondary filter for match or game, verifty
			// that this log entry qualifies
			var passesFilter = function () {
				if (filter && filter.type) {
					if (filter.type === "game") {
						return filterLookup[gameId] ? true : false;
					}

					if (filter.type === "match") {
						return filterLookup[matchId] ? true : false;
					}

					if (filter.type === "player") {
						if (filterLookup[playerId]) {
							return true;
						}
						if (filterLookup[serverId]) {
							return true;
						}
						if (filterLookup[asstId]) {
							return true;
						}
						if (filterLookup[setterId]) {
							return true;
						}
						return false;
					}
				}
				// no filter
				return true;
			};

			// Grouping my game or match, is it correct?
			if (getGroupingType() === "game") {
				return lookup[gameId] && passesFilter() ? true : false;
			}

			if (getGroupingType() === "match") {
				return lookup[matchId] && passesFilter() ? true : false;
			}

			if (getGroupingType() === "rot_serv") {
				return lookup[rotServId] && passesFilter() ? true : false;
			}

			if (getGroupingType() === "rotation") {
				return lookup[rotationId] && passesFilter() ? true : false;
			}

			return passesFilter();

		};

		var isPlayerFilter = function () {
			if (filter && filter.type && filter.type === "player") {
				return true;
			} else {
				return false;
			}
		};

		var isProperPlayer = function (playerId) {
			if (playerId == filter.list[0]) {
				return true;
			} else {
				return false;
			}
		};

		var isDesiredPlayerStat = function (who, playerId) {

			if (who !== "us") {
				return false;
			}

			if (!isPlayerFilter()) {
				return true;
			}

			if (isPlayerFilter() && isProperPlayer(playerId)) {
				return true;
			}

			return false;
		};

		var getPlayerId = function (row, key) {
			if (grouping.type === "rot_serv_player") {
				if (row[key]) {
					return row.rot_serv + ":" + row[key];
				} else {
					return row[key];
				}
			} else {
				return row[key];
			}
		};

		var getTeamPlayerId = function (row) {
			var teamPlayerId = CONFIG.TEAM_STAT_ID;
			if (grouping.type === "rot_serv_player") {
				return row.rot_serv + ":" + teamPlayerId;
			} else {
				return teamPlayerId;
			}
		};

		var getServerId        = function (row) { return getPlayerId(row, "server");     };
		var getSetterId        = function (row) { return getPlayerId(row, "setter");     };
		var getNonSetterId     = function (row) { return getPlayerId(row, "asstnum");    };
		var getBlockerId       = function (row) { return getPlayerId(row, "player_num"); };
		var getSharedBlockerId = function (row) { return getPlayerId(row, "asstnum");    };

		var getNonSetterName     = function (row) { return row.asstname;    };
		var getBlockerName       = function (row) { return row.player_name; };
		var getSharedBlockerName = function (row) { return row.asstname;    };

		// incrementing the non-primary player for the row
		// TODO: should this also add player? probably ....
		var incSecondaryPlayerStat = function (id, playerId, gameId, who, key) {
			if (isDesiredPlayerStat(who, playerId)) {
				var statId = isPlayerFilter() ? id : playerId;
				inc(statId, who, key);
				gameCount[statId][gameId] = true;
			}
		};

		var processPlayerStats = function (id, row, isNewPoint, nextRow) {

			nextRow = nextRow === undefined ? {} : nextRow;

			// DRY
			//var logType    = row.log_type;
			//var teamId     = row.team_id;
			//var matchId    = row.match_id;
			var gameId     = row.game_id;
			var serving    = row.serving;
			var rotServId  = row.rot_serv;
			//var rotationId = row.rotation;
			var playerId   = row.player_num;
			var playerTeam = row.player_team;
			var setterId   = getSetterId(row);
			var serverId   = getServerId(row);
			//var asstId     = row.asstnum;
			var lineup     = row.lineup;

			var statId;

			// ------
			// Server
			// ------
			if (serverId && actionResultsInPoint(row["action"])) {

				// Add player regardless if they have a stat
				// TODO: this should be moved elsewhere, not requiring an action
				addPlayerIfNotExists(serverId);

				// Special handling for filtering by player:
				//
				// If we are filtering by player, we do two things:
				// 1. verify the end stat is for the filtered player
				// 2. verify that the stat we are associating with has the proper
				//    id.  not that of the playerId, since we are not keying off
				//    of that.

				if (isDesiredPlayerStat(who, serverId)) {
					if (isPlayerFilter()) { statId = id; } else { statId = serverId; }
					inc(statId, who, "serve_attempts");
					gameCount[statId][gameId] = true;

					if (row["point"] === who) {
						add(statId, "points_scored_on_serve", row["points"]);

						// if opponent receive error, this counts as an ace
						if (row["action"] === "errors" && row["code"] === "rcv") {
							inc(statId, who, "earned", "ace");
						}
					} else if (row["detail"] && row["code"] === "srv") {
						// count serve error detail
						if (row["detail"] === "out"){
							inc(statId, playerTeam, "serve_error_detail_out");
						} else if (row["detail"] === "net"){
							inc(statId, playerTeam, "serve_error_detail_net");
						}
					}

				}
			}

			if (actionResultsInPoint(row["action"]) && row["detail"] && row["code"] !== "srv") {
				// count attack error detail
				if (row["detail"] === "out"){
					inc(id, playerTeam, "attack_error_detail_out");
				} else if (row["detail"] === "net"){
					inc(id, playerTeam, "attack_error_detail_net");
				} else if (row["detail"] === "blocked"){
					inc(id, playerTeam, "attack_error_detail_blocked");
				}
			}

			// ------
			// Setter
			// ------
			if (setterId && actionResultsInPoint(row["action"])) {

				// Add player regardless if they have a stat
				addPlayerIfNotExists(setterId);

				if (isDesiredPlayerStat(who, setterId)) {
					statId = isPlayerFilter() ? id : setterId;
					gameCount[statId][gameId] = true;
					gameAsSetterCount[statId][gameId] = true;
				}

				// assign the assist to the proper player
				// Ignoring opponent team
				if (playerTeam === who && row.point === who && isActionAssisted(row.action, row.code)) {

					if (row["asstnum"]) {

						// If the assist is the opposing team, this means do not count assist
						if (!(row["asstnum"] === "00" && row["asstname"] === "Their Player")) {

							let nonSetterId   = getNonSetterId(row);
							let nonSetterName = getNonSetterName(row);
							addPlayerIfNotExists(nonSetterId, nonSetterName);

							// is the nonSetter actually the setter?
							if (setterId == nonSetterId) {
								incSecondaryPlayerStat(id, setterId, gameId, who, "assists_as_setter");
								incSecondaryPlayerStat(id, setterId, gameId, who, "set_attempts");
							} else {
								incSecondaryPlayerStat(id, nonSetterId, gameId, who, "assists_as_non_setter");
								incSecondaryPlayerStat(id, nonSetterId, gameId, who, "set_attempts");
							}
						}

					} else {
						// older method (implicit setter)
						if (isActionAssisted(row.action, row.code)) {
							if (row.code === "killo") {
								// this is a team assist and set_attempt, handled with
								// the rest of (team) stats below
							} else {
								// Do not record implicit assist if setter wins the point
								if (setterId != playerId) {
									incSecondaryPlayerStat(id, setterId, gameId, who, "assists_as_setter");
									incSecondaryPlayerStat(id, setterId, gameId, who, "set_attempts");
								}
							}
						}
					}
				}

				// handling shared blocks (only relevant for player by player listing)
				if (isActionBlock(row.action, row.code)) {

					if (row["point"] === who) {

						let blockerId   = getBlockerId(row);
						let blockerName = getBlockerName(row);
						addPlayerIfNotExists(blockerId, blockerName);

						if (row["asstnum"]) {
							let sharedBlockerId   = getSharedBlockerId(row);
							let sharedBlockerName = getSharedBlockerName(row);
							addPlayerIfNotExists(sharedBlockerId, sharedBlockerName);

							incSecondaryPlayerStat(id, sharedBlockerId, gameId, who, "block_assists");
							incSecondaryPlayerStat(id, blockerId, gameId, who, "block_assists");

						} else {
							// old logs have no shared blocks
							incSecondaryPlayerStat(id, blockerId, gameId, who, "blocks");
						}
					} else {
						// opponent got a block - no special handling
						// TODO: build a test
						inc(id, opp, "blocks");
					}
				}

			} else if (!setterId && who === "us" && actionResultsInPoint(row["action"]) && isActionBlock(row.action, row.code)) {
				// calculate blocks in Touch where there is no setter or opponent
				let blockerId   = getBlockerId(row);
				let blockerName = getBlockerName(row);
				addPlayerIfNotExists(blockerId, blockerName);

				if (row["asstnum"]) {
					let sharedBlockerId   = getSharedBlockerId(row);
					let sharedBlockerName = getSharedBlockerName(row);
					addPlayerIfNotExists(sharedBlockerId, sharedBlockerName);

					incSecondaryPlayerStat(id, sharedBlockerId, gameId, who, "block_assists");
					incSecondaryPlayerStat(id, blockerId, gameId, who, "block_assists");

				} else {
					// old logs have no shared blocks
					incSecondaryPlayerStat(id, blockerId, gameId, who, "blocks");
				}
			}

			if (isDesiredPlayerStat(who, playerId) && row["code"] === "htatt") {
				if (row["detail"] && row["detail"] === "non-setter"){
					// count "Hit Still In Play - Not From Setter" entry
					inc(id, who, "htatt_noset");
				} else if (row["asstnum"] && row["asstnum"] !== setterId){
					// count entry with specified Set (assist) from player other than designated setter
					inc(id, who, "htatt_noset_specified");
				}
			}

			// Stats defined in relation to the following stat
			if (isDesiredPlayerStat(who, playerId) && nextRow["code"]) {

				if (playerTeam === who) {

					// First Ball Side Out
					if (isActionPass(row.action, row.code) && isActionAboveTheNetAttack(nextRow.action, nextRow.code)) {
						inc(id, who, "first_ball_side_out");
					}
					// Kill to Dig
					if (isActionDig(row.action, row.code) && isActionAboveTheNetAttack(nextRow.action, nextRow.code)) {
						inc(id, who, "kill_to_dig");
					}
					// Attack Attempt to Dig
					if (isActionDig(row.action, row.code) && isActionAboveTheNetAttackAttempt(nextRow.action, nextRow.code)) {
						inc(id, who, "attack_attempt_to_dig");
					}
				}
			}


			// ----------------------------------------------------
			// points played and games played for players in lineup
			// ----------------------------------------------------
			if (playerTeam === who) {
				if (lineup && lineup.length) {
					var i;
					for (i = 0; i < lineup.length; i++) {
						if (lineup[i]) {
							if (getGrouping().type === 'rot_serv_player') {
								id = rotServId + ":" + playerId;
							} else {
								id = lineup[i];
							}
							if (!isPlayerFilter()) {
								addPlayerIfNotExists(id);
								gameCount[id][gameId] = true;
							}
						}
					}
				}

			}

			// -------------------------------------------
			// Team player (unidentified individual) stats
			// -------------------------------------------

			if (!isPlayerFilter()) {

				var teamPlayerId = getTeamPlayerId(row);
				var teamPlayerName = CONFIG.TEAM_STAT_NAME;

				// Set attempts for unknown setters (hits still in play, opponent blocks
				if (playerTeam === who) {
					if (isActionMissedAssist(row["action"], row["code"])) {
						addPlayerIfNotExists(teamPlayerId, teamPlayerName);
						incSecondaryPlayerStat(id, teamPlayerId, gameId, who, "set_attempts");
					}

					if (row["action"] === "earned" && row['code'] === 'killo') {
						// Old stat, where kill was set by unidentified non-setter
						addPlayerIfNotExists(teamPlayerId, teamPlayerName);
						incSecondaryPlayerStat(id, teamPlayerId, gameId, who, "set_attempts");
						incSecondaryPlayerStat(id, teamPlayerId, gameId, who, "assists_as_non_setter");
					}
				}

				if (playerTeam === opp) {
					// process opponent block/dig errors (our kills)
					if (row.action === "errors" && (row.code === "dig" || row.code === "blck")) {
						addPlayerIfNotExists(teamPlayerId, teamPlayerName);
						incSecondaryPlayerStat(id, teamPlayerId, gameId, who, "opponent_derived_kills");
					}

					// process opponent aces (our receive errors)
					if (row.action === "earned" && row.code === "ace") {
						addPlayerIfNotExists(teamPlayerId, teamPlayerName);
						incSecondaryPlayerStat(id, teamPlayerId, gameId, who, "opponent_derived_rcv_errors");

						// TODO: check to see how this works on per game averages, etc.
					}

				}

				if (serving === opp) {
					if (isNewPoint && !isActionReceive(row.action, row.code)) {
						if (row.action === "errors" && row.code === "srv") {
							// ignore opponent serve fails
						} else if (row.action === "faults" && row.code === "foot") {
							// ignore opponent serve fails
						} else {
							if (row.action === "earned" && row.code === "ace") {
								// this is counted above as "opponent_derived_rcv_errors", and is used
								// in the pass_attempt count
							} else {
								// is action not a serve result ...
								addPlayerIfNotExists(teamPlayerId, teamPlayerName);
								incSecondaryPlayerStat(id, teamPlayerId, gameId, who, "x_serves_received");
							}
						}
					}
				}

				// TODO: shouldn't the team player be around for every game? yes.
			}
		};


		var processGroupStats = function (id, row) {

			// DRY
			//var logType    = row.log_type;
			//var teamId     = row.team_id;
			//var matchId    = row.match_id;
			//var gameId     = row.game_id;
			//var rotServId  = row.rot_serv;
			//var rotationId = row.rotation;
			var playerId   = row.player_num;
			var playerTeam = row.player_team;
			var setterId   = getSetterId(row);
			//var serverId   = getServerId(row);
			//var asstId     = row.asstnum;

			// End Point Handling
			// ------------------

			if (actionResultsInPoint(row['action'])) {

				// Serving
				// -------
				var srvTeam = row.serving === who ? who : opp;
				var rcvTeam = row.serving === who ? opp : who;

				inc(id, srvTeam, "serve_attempts");
				inc(id, rcvTeam, "opponent_serve_attempts");
				if (row.action === "errors") {
					if (row.code === "srv") {
						inc(id, rcvTeam, "opponent_serve_errors");
					}
				}
				if (row.action === "faults") {
					if (row.code === "foot") {
						inc(id, rcvTeam, "opponent_serve_errors");
					}
				}

				if (row["serving"] === who) {

					// TODO: move this to an us/them hash
					if (row.point === who) {
						add(id, "points_scored_on_serve", row["points"]);

						// if opponent receive error, this counts as an ace
						if (row["action"] === "errors" && row["code"] === "rcv") {
							inc(id, who, "earned", "ace");
						}
					} else if (row["detail"] && row["code"] === "srv") {
						// count serve error detail
						if (row["detail"] === "out"){
							inc(id, playerTeam, "serve_error_detail_out");
						} else if (row["detail"] === "net"){
							inc(id, playerTeam, "serve_error_detail_net");
						}
					}
				} else {

					// TODO: move this to an us/them hash
					if (row.point !== who) {
						add(id, "opponent_points_scored_on_serve", row["points"]);
					}
				}

				if (actionResultsInPoint(row["action"]) && row["detail"] && row["code"] !== "srv") {
					// count attack error detail
					if (row["detail"] === "out"){
						inc(id, playerTeam, "attack_error_detail_out");
					} else if (row["detail"] === "net"){
						inc(id, playerTeam, "attack_error_detail_net");
					} else if (row["detail"] === "blocked"){
						inc(id, playerTeam, "attack_error_detail_blocked");
					}
				}

				// Scoring
				// -------

				if (row.point === who) {
					add(id, "points_for", row.points);
				} else if (row['point'] !== who) {
					add(id, "points_against", row.points);
				}
			}

			// Assists - Us
			// ------------
			if (playerTeam === "us" && row.point === who && isActionAssisted(row.action, row.code)) {

				if (row.asstnum) {

					var nonSetterId   = getNonSetterId(row);

					if (setterId == nonSetterId) {
						inc(id, who, "assists_as_setter");
					} else {
						inc(id, who, "assists_as_non_setter");
					}

				} else {
					// legacy log
					// Note: Even though we didn't count tips and dbhs as assists
					// in the old code, since we're counting them as assists for
					// opponents, we are counting them for ourselves as well.
					// TODO: make sure this works for players stats as well.
					if (isActionAssisted(row.action, row.code)) {
						if (row.code === "killo") {
							inc(id, who, "assists_as_non_setter");
						} else {
							if (setterId != playerId) {
								inc(id, who, "assists_as_setter");
							}
						}
					}
				}
				if (setterId != playerId) {
					inc(id, who, "set_attempts");
				}

			}

			// Assists - Them
			// TODO: I'm not sure we actually count on this, or if we just derive in Stats
			if (row.point === opp && isActionAssisted(row.action, row.code)) {
				if (row.code === "killo") {
					inc(id, opp, "assists_as_non_setter");
					// we know this was someone other than their setter
				} else {
					// attribute to setter
					inc(id, opp, "assists_as_setter");
				}
				inc(id, opp, "set_attempts");
			}

			if (isActionBlock(row.action, row.code)) {
				if (row["point"] === who) {
					if (row["asstnum"]) {
						// twice
						inc(id, who, "block_assists");
						inc(id, who, "block_assists");
					} else {
						inc(id, who, "blocks");
					}
				} else {
					inc(id, opp, "blocks");
				}
			}

			// handle hits still in play for set attempts
			if (playerTeam === who && isActionMissedAssist(row["action"], row["code"])) {
				inc(id, who, "set_attempts");
			}
			if (row.code === "htatt"){
				if (row["detail"] && row["detail"] === "non-setter"){
					// count "Hit Still In Play - Not From Setter" entry
					inc(id, who, "htatt_noset");
				} else if (row["asstnum"] && row["asstnum"] !== setterId){
					inc(id, who, "htatt_noset_specified");
				}
			}

		};

		// we have the totals, now iterate over matches for per/game(match?) values
		var processTotalsByRow = function () {

			var i, len, id, gameId, playerId;
			var result = [];

			for (i = 0, len = getGrouping().list.length; i < len; i++) {

				id = getGrouping().list[i];

				// verify we got data for this part of getGrouping()
				if (getGrouping().type === 'match') {
					if (!totals[id]['match_id']) {
						continue;
					}
				} else if (getGrouping().type === 'game') {
					if (!totals[id]['game_id']) {
						continue;
					}
				} else if (getGrouping().type === 'rot_serv') {
					if (!totals[id]['rot_serv']) {
						continue;
					}
				} else if (getGrouping().type === 'rot_serv_player') {
					if (!totals[id]['rot_serv_player']) {
						continue;
					}
				} else if (getGrouping().type === 'rotation') {
					if (!totals[id]['rotation']) {
						continue;
					}
				}

				var gamesPlayed         = countKeys(gameCount[id], CONFIG.TEAM_STAT_ID);
				var gamesPlayedAsSetter = countKeys(gameAsSetterCount[id]);
				var pointsPlayed        = Object.keys(pointsPlayedIn[id]).length;
				var pointsPlayedServer  = Object.keys(pointsPlayedInServerCount[id]).length;
				var pointsPlayedSetter  = Object.keys(pointsPlayedInSetterCount[id]).length;
				var pointsPlayedAsst    = Object.keys(pointsPlayedInAsstCount[id]).length;
				var pointsPlayedLineup  = Object.keys(pointsPlayedInLineupCount[id]).length;

				if (getGrouping().type === "player") {
					// Make sure we have at least one game played
					if (gamesPlayed <= 0) {
						// console.log("-> player id: " + id + ", has no games played, skipping.");
						continue;
					}
					// Set player number and name
					setTotal(id, "player_num", id);
					setTotal(id, "player_name", getGrouping().listNames[id]);
				}

				if (getGrouping().type === 'rot_serv_player') {
					var matches = id.match(/(\d)+:(us|them):(\w+)/);
					if (matches) {
						setTotal(id, "player_num", matches[3]);
						setTotal(id, "player_name", getGrouping().listNames[matches[3]]);
					}

				}

				totals[id]["type"]                   = getGrouping().type;
				totals[id]["id"]                     = id;
				totals[id]["games_played"]           = gamesPlayed;
				totals[id]["games_played_as_setter"] = gamesPlayedAsSetter;
				totals[id]["points_played"]          = pointsPlayed + pointsPlayedServer + pointsPlayedSetter + pointsPlayedAsst + pointsPlayedLineup;
				totals[id]["points_played_server"]   = pointsPlayedServer;
				totals[id]["points_played_setter"]   = pointsPlayedSetter;
				totals[id]["points_played_asst"]     = pointsPlayedAsst;
				totals[id]["points_played_lineup"]   = pointsPlayedLineup;

				if (getGrouping().type === "player" && id === CONFIG.TEAM_STAT_ID) {
					// ignore team player
				} else {

					agg["games_played"]  += gamesPlayed;
					agg["points_played"] += pointsPlayed;
					agg["points_played"] += pointsPlayedSetter;
					agg["points_played"] += pointsPlayedServer;
					agg["points_played"] += pointsPlayedAsst;
					agg["points_played"] += pointsPlayedLineup;
					agg["points_played_setter"] += pointsPlayedSetter;
					agg["points_played_server"] += pointsPlayedServer;
					agg["points_played_asst"] += pointsPlayedAsst;
					agg["points_played_lineup"] += pointsPlayedLineup;
				}

				// Post process the hit attempts if we're not going by rotations
				if (getGrouping().type !== "rot_serv_player" && getGrouping().type !== "rotation" && getGrouping().type !== "rot_serv") {

					for (gameId in totals[id]['us']['edit']['combined_kill_attempts_by_game']) {
						for (playerId in totals[id]['us']['edit']['combined_kill_attempts_by_game'][gameId]) {
							if (getGrouping().type === 'player') {
								// verify that the player is the ID
								if (playerId != id) {
									continue;
								}
							}
							// If we're filtering by player, make sure we're only handling for them
							if (filter && filter.type === "player" && !filterLookup[playerId]) {
								continue;
							}
							totals[id]['us']['edit']['combined_kill_attempts'] += totals[id]['us']['edit']['combined_kill_attempts_by_game'][gameId][playerId];
							agg['us']['edit']['combined_kill_attempts']        += totals[id]['us']['edit']['combined_kill_attempts_by_game'][gameId][playerId];
						}
					}
					for (gameId in totals[id]['us']['edit']['digs_by_game']) {
						for (playerId in totals[id]['us']['edit']['digs_by_game'][gameId]) {
							if (getGrouping().type === 'player') {
								// verify that the player is the ID
								if (playerId != id) {
									continue;
								}
							}
							// If we're filtering by player, make sure we're only handling for them
							if (filter && filter.type === "player" && !filterLookup[playerId]) {
								continue;
							}
							totals[id]['us']['edit']['digs'] += totals[id]['us']['edit']['digs_by_game'][gameId][playerId];
							agg['us']['edit']['digs']        += totals[id]['us']['edit']['digs_by_game'][gameId][playerId];
						}
					}
					for (gameId in totals[id]['us']['edit']['block_attempts_by_game']) {
						for (playerId in totals[id]['us']['edit']['block_attempts_by_game'][gameId]) {
							if (getGrouping().type === 'player') {
								// verify that the player is the ID
								if (playerId != id) {
									continue;
								}
							}
							// If we're filtering by player, make sure we're only handling for them
							if (filter && filter.type === "player" && !filterLookup[playerId]) {
								continue;
							}
							totals[id]['us']['edit']['block_attempts'] += totals[id]['us']['edit']['block_attempts_by_game'][gameId][playerId];
							agg['us']['edit']['block_attempts']        += totals[id]['us']['edit']['block_attempts_by_game'][gameId][playerId];
						}
					}
				}

				result.push(totals[id]);
			}

			return result;
		};

		// Get the grouping related ID, and set the IDs for match, game, and team
		// if needed.
		var getGroupingId = function (row) {

			var id;

			if (getGrouping().type === "game") {
				id = row.game_id;

			} else if (getGrouping().type === "match") {
				id = row.match_id;

			} else if (getGrouping().type === "rot_serv") {
				id = row.rot_serv;

			} else if (getGrouping().type === "rotation") {
				id = row.rotation;

			} else if (getGrouping().type === "player") {
				id = row.player_num;

			} else if (getGrouping().type === "rot_serv_player") {
				id = row.rot_serv + ":" + row.player_num;

			}
			return id;
		};

		var setGeneralIds = function (id, row) {

			if (getGrouping().type === "game") {
				setTotal(id, "game_id",  row.game_id);
				setTotal(id, "match_id", row.match_id);
				setTotal(id, "team_id",  row.team_id);

			} else if (getGrouping().type === "match") {
				setTotal(id, "match_id", row.match_id);
				setTotal(id, "team_id",  row.team_id);

			} else if (getGrouping().type === "rot_serv") {
				setTotal(id, "rot_serv", row.rot_serv);

			} else if (getGrouping().type === "rotation") {
				setTotal(id, "rotation", row.rotation);

			}
		};


		// ----------------
		// Manual Procesing
		// ----------------

		var setManualTotals = function (processedGameId, hasManualEntries) {
			// Set totals for manual entries
			if (isPlayerFilter()) {

				if (getGrouping().type === 'game') {
					processManualPlayerFilteredStatsForGame();
				} else if (getGrouping().type === 'match') {
					processManualPlayerFilteredStatsForMatch();
				}

			} else {

				if (hasManualEntries) {

					if (getGrouping().type === "player") {
						processManualPlayerStats(processedGameId);

					} else if (getGrouping().type === "game" || getGrouping().type === "match") {
						processManualGroupStats();
					}
				}
			}

		};

		var setManualTotalsForPlayerFilteredGame = function (id, gameId) {
			_setManualTotalsFor("player_by_game", id, gameId);
		};

		var setManualTotalsForPlayerFilteredMatch = function (id, gameId, matchId) {
			_setManualTotalsFor("player_by_match", id, gameId, matchId);
		};

		//var setManualTotalsForPlayer = function () {
		//	This is handled a bit differently
		//};

		var setManualTotalsForGroup = function (id, gameId) {
			_setManualTotalsFor("group", id, gameId);
		};

		var _setManualTotalsFor = function (type, id, gameId, matchId) {

			var editHitAttempts, editDigs, editBlockAttempts, playerId;

			let game = webReport.get_xgame()[gameId];
			var manualData = editData.getManualEntriesForGame(game);

			if (manualData) {
				if (manualData.htatt) {
					for (let i=0, len = manualData.htatt.length; i < len; i++) {
						playerId = manualData.htatt[i].player_num;
						editHitAttempts = manualData.htatt[i].count;
						if (!isDesiredPlayerStat(who, playerId)) {
							continue;
						}

						if (editHitAttempts) {

							totals = setManualValue(totals, id, 'combined_kill_attempts_by_game', gameId, playerId, editHitAttempts);
							if (type === "player_by_game") {
								setTotal(id, "game_id", gameId);
							} else if (type === "player_by_match") {
								setTotal(id, "match_id", matchId);
							}
						}
					}
				}
				if (manualData.dgatt) {
					for (let i=0, len = manualData.dgatt.length; i < len; i++) {
						playerId = manualData.dgatt[i].player_num;
						editDigs = manualData.dgatt[i].count;
						if (!isDesiredPlayerStat(who, playerId)) {
							continue;
						}

						if (editDigs) {

							totals = setManualValue(totals, id, 'digs_by_game', gameId, playerId, editDigs);
							if (type === "game") {
								setTotal(id, "game_id", gameId);
							} else if (type === "match") {
								setTotal(id, "match_id", matchId);
							}
						}
					}
				}
				if (manualData.blatt) {
					for (let i=0, len = manualData.blatt.length; i < len; i++) {
						playerId = manualData.blatt[i].player_num;
						editBlockAttempts = manualData.blatt[i].count;

						if (!isDesiredPlayerStat(who, playerId)) {
							continue;
						}

						if (editBlockAttempts) {
							totals = setManualValue(totals, id, 'block_attempts_by_game', gameId, playerId, editBlockAttempts);
							if (type === "player_by_game") {
								setTotal(id, "game_id", gameId);
							} else if (type === "player_by_match") {
								setTotal(id, "match_id", matchId);
							}
						}
					}
				}
			} // if (manualData)
		};

		var processManualPlayerFilteredStatsForGame = function () {

			var i, len, gameId;

			for (i = 0, len = getGrouping().list.length; i < len; i++) {

				if (getGrouping().type === "game") {
					gameId = getGrouping().list[i];
				}

				setManualTotalsForPlayerFilteredGame(gameId, gameId);
			}
		};

		var processManualPlayerFilteredStatsForMatch = function () {

			var i, iLen, k, kLen, gameId, matchId;

			for (i = 0, iLen = getGrouping().list.length; i < iLen; i++) {

				matchId = getGrouping().list[i];

				var games = webReport.getGamesByMatch(matchId);

				for (k = 0, kLen = games.length; k < kLen; k++) {

					gameId = games[k];
					setManualTotalsForPlayerFilteredMatch(matchId, gameId, matchId);
				} // for (k) loop
			} // for (i) loop
		};

		var processManualPlayerStats = function (processedGameId) {

			for (let gameId in processedGameId) {
				let game = webReport.get_xgame()[gameId];

				for (let i=0, len=getGrouping().list.length; i < len; i++) {
					let playerId = getGrouping().list[i];
					let id = playerId;

					let editHitAttempts = editData.getHitAttemptsForPlayer(game, { num: playerId });
					if (editHitAttempts) {
						totals = setManualValue(totals, id, "combined_kill_attempts_by_game", gameId, playerId, editHitAttempts);
						gameCount[id][gameId] = true;
					}

					let editDigs = editData.getDigAttemptsForPlayer(game, { num: playerId });
					if (editDigs) {
						totals = setManualValue(totals, id, "digs_by_game", gameId, playerId, editDigs);
						gameCount[id][gameId] = true;
					}

					let editBlockAttempts = editData.getBlockAttemptsForPlayer(game, { num: playerId });
					if (editBlockAttempts) {
						totals = setManualValue(totals, id, "block_attempts_by_game", gameId, playerId, editBlockAttempts);
						gameCount[id][gameId] = true;
					}
				}
			}
		};

		var processManualGroupStats = function () {

			var id, gameId;

			// for game and match, we can go off of gameCount and ID
			// (since we are guaranteed to have each id)
			for (id in gameCount) {
				for (gameId in gameCount[id]) {
					setManualTotalsForGroup(id, gameId);
				}
			}
		};

		return {
			totals                   : totals,
			agg                      : agg,
			setTotal                 : setTotal,
			add                      : add,
			init                     : init,
			inc                      : inc,
			getGroupingType          : getGroupingType,
			lookup                   : lookup,
			getGrouping              : getGrouping,
			gameCount                : gameCount,
			gameAsSetterCount        : gameAsSetterCount,
			setPointPlayedIn         : setPointPlayedIn,
			initGroupingArraysForId  : initGroupingArraysForId,
			initGroupingArraysForAgg : initGroupingArraysForAgg,
			addPlayerIfNotExists     : addPlayerIfNotExists,
			isCorrectLogRow          : isCorrectLogRow,
			isPlayerFilter           : isPlayerFilter,
			isProperPlayer           : isProperPlayer,
			isDesiredPlayerStat      : isDesiredPlayerStat,
			processPlayerStats       : processPlayerStats,
			processGroupStats        : processGroupStats,
			processTotalsByRow       : processTotalsByRow,
			setManualTotals          : setManualTotals,
			getGroupingId            : getGroupingId,
			setGeneralIds            : setGeneralIds
		};
	};

	// ============== //
	// PUBLIC METHODS //
	// ============== //

	var filterAndAggregateData = function (data, grouping, filter, hasEditStats) {

		App.debugLog("processStatsIteration begin.");

		var aggregate = new Aggregate();
		aggregate.init(grouping, filter);

		// hasEditStats defaults to true
		hasEditStats = hasEditStats === undefined ? true : hasEditStats;

		var hasManualEntries  = false;
		var processedGameId  = {};

		var i, len;

		var who = "us";
		var opp = "them";

		var pointCount = 0;

		if (!data || !data.length) {
			return [];
		}

		// now, iterate through our data
		for (i=0, len = data.length; i < len; i++) {

			var row = data[i];

			var logType    = row.log_type;
			//var teamId     = row.team_id;
			var matchId    = row.match_id;
			var gameId     = row.game_id;
			var rotServId  = row.rot_serv;
			var rotationId = row.rotation;
			var playerId   = row.player_num;
			var playerTeam = row.player_team;
			var setterId   = row.setter;
			var serverId   = row.server;
			var asstId     = row.asstnum;
			//var actionId   = row.action;
			var lineup     = row.lineup;
			//var pointId    = row.point;
			//var detail     = row.detail;

			// TODO: For now, just ignore the timeouts, substitutions, and partials
			if (logType === "timeout" || logType === "substitution" || logType === "partialEntry") {
				continue;
			}

			// Do we want to process this row?
			if (!aggregate.isCorrectLogRow(gameId, matchId, playerId, setterId, serverId, asstId, rotServId, rotationId)) {
				continue;
			}

			let game;
			// This is to ensure some tests can run properly
			if (webReport && webReport.get_xgame()) {
				game = webReport.get_xgame()[gameId];
			}

			processedGameId[gameId] = true;
			if (hasEditStats) {
				if (!hasManualEntries && editData.hasManualEntriesForGame(game)) {
					// once we know we have manual entries, no need to check again
					hasManualEntries = true;
				}
			}

			var id = aggregate.getGroupingId(row);
			if (!id && playerTeam !== "them"){
				// if id is blank due to malformed or partial log entry, just move on to the next entry
				// (exception for "them" actions which can have no id)
				continue;
			}

			// For rotation, ensure we have the correct one
			// TODO: should this be for all rotation related queries?
			if (aggregate.getGrouping().type === "rot_serv_player") {
				if (+row.rotation !== +aggregate.getGrouping().rotation) {
					continue;
				}
				if (row.serving !== aggregate.getGrouping().serving) {
					continue;
				}
			}

			aggregate.setGeneralIds(id, row);

			// correct match, grab cumulative stats

			if (logType === "adjustment") {
				if (
					aggregate.getGrouping().type === "match" ||
					aggregate.getGrouping().type === "game"  ||
					aggregate.getGrouping().type === "rotation"
				) {
					// this is the only facet that adjustments should affect
					aggregate.add(id, "points_for",     row["us_delta"]);
					aggregate.add(id, "points_against", row["them_delta"]);

					var usDelta    = parseInt(row.us_delta, 10) || 0;
					var themDelta  = parseInt(row.them_delta, 10) || 0;

					aggregate.add(id, "us_adjustment", usDelta);
					aggregate.add(id, "them_adjustment", themDelta);

					continue;
				} else {
					continue;
				}
			}

			if (isNewPoint(data, i)) {
				pointCount++;
			}

			// We're processing hit attempts here because we need to take into
			// account manual hit entries on a per game, per player basis
			//
			// - If we're not getGrouping() by rotation
			// - If the action is a hit attempt
			// - If there are manual hit attempt entries
			//   - Track the manual hit attempt entries on a per game basis
			// - If not, just track as a single attempt
			//
			// TODO: we should be able to calculate this for rotations
			if (
				aggregate.getGrouping().type !== "rot_serv_player" &&
				aggregate.getGrouping().type !== "rotation" &&
				aggregate.getGrouping().type !== "rot_serv"
			) {
				if (playerTeam === who && aggregate.isDesiredPlayerStat(who, playerId)) {
					aggregate.totals = processManualEntries(aggregate.totals, row, playerTeam, id, game, playerId, hasEditStats);
				}
				if (playerTeam === opp) {
					if (row.action === "errors" && (row.code === "dig" || row.code === "blck")) {
						var teamPlayerId = CONFIG.TEAM_STAT_ID;
						if (aggregate.getGrouping().type === "player") {
							aggregate.totals = processManualEntriesForDerivedKill(aggregate.totals, row, "us", teamPlayerId, game, teamPlayerId, hasEditStats);
						} else {
							aggregate.totals = processManualEntriesForDerivedKill(aggregate.totals, row, "us", id, game, teamPlayerId, hasEditStats);
						}
					}
				}
			}

			// If we're handling individual player stats
			if (
				(aggregate.getGrouping().type === "player") ||
				(aggregate.getGrouping().type === "rot_serv_player") ||
				aggregate.isPlayerFilter()
			) {
				aggregate.processPlayerStats(id, row, isNewPoint(data, i), data[i+1]);

			} else {
				aggregate.processGroupStats(id, row);
			}

			// We're just handling our own players
			if (aggregate.getGrouping().type === "rot_serv_player") {

				if (playerTeam !== "us") {
					continue;
				}
				aggregate.addPlayerIfNotExists(rotServId + ":" + playerId);
				aggregate.setTotal(id, "rot_serv_player", id);
			}

			if (aggregate.getGroupingType() !== "player") {
				aggregate.setPointPlayedIn(id, pointCount);
			} else {
				if (lineup && lineup.length > 0) {
					for (let j=0; j < lineup.length; j++) {
						aggregate.setPointPlayedIn(lineup[j], pointCount);
					}
				} else {
					aggregate.setPointPlayedIn(id, pointCount);

					if (serverId != id && serverId !== null) {
						aggregate.setPointPlayedIn(serverId, pointCount, 'server');
					}

					if (setterId != id && setterId != serverId && setterId !== null) {
						aggregate.setPointPlayedIn(setterId, pointCount, 'setter');
					}

					if (asstId != id && asstId != serverId && asstId != setterId && asstId !== null) {
						aggregate.setPointPlayedIn(asstId, pointCount, 'assist');
					}
				}
			}

			// We're just handling our own players
			if (aggregate.getGrouping().type === "player") {

				if (playerTeam !== "us") {
					continue;
				}

				// Ensure that we're tracking primary player, even if there
				// were no stats handled.
				aggregate.addPlayerIfNotExists(playerId, row.player_name);
			}

			// TODO: basically, i'm coming down here with x:them, and we shouldn't be.

			aggregate.gameCount[id][gameId] = true;

			// Tracking the primary action
			if (aggregate.isDesiredPlayerStat(who, playerId)) {
				var action = row.action;
				aggregate.inc(id, playerTeam, action, "cnt");
				aggregate.inc(id, playerTeam, action, row.code);
			}

		} // end of data iteration

		aggregate.agg["games_played"]  = 0;
		aggregate.agg["points_played"] = 0;
		aggregate.agg["points_played_server"] = 0;
		aggregate.agg["points_played_setter"] = 0;
		aggregate.agg["points_played_asst"] = 0;
		aggregate.agg["points_played_lineup"] = 0;

		aggregate.setManualTotals(processedGameId, hasManualEntries);

		var result = aggregate.processTotalsByRow();
		// if there is a game filter, ensure game count is sum of games in filter
		if (filter && filter.type === "game") {
			aggregate.agg["games_played"] = filter.list.length;
		}

		return { byRow: result, agg: aggregate.agg, hasManualEntries: hasManualEntries };

	};

	var filterAndAggregatePointLogData = function (data, grouping, playerNames) {
		var lookup  = {};
		var result  = [];
		var agg     = {};
		var logsObj = {};

		var initGroupingArraysForId = function (id) {
			logsObj[id] = {};
			lookup[id]  = true;

			initGroupingArrays(logsObj[id]);
		};

		var initGroupingArraysForAgg = function () {
			agg = {};
			initGroupingArrays(agg);
		};

		var initGroupingArrays = function (arg) {

			arg['rotation_log']    = 0;
			arg['rally_log']       = 0;
			arg['stage_log']       = 0;
			arg['playernum']       = 0;
			arg['playername']      = 0;
			arg['action_type_log'] = 0;
			arg['action_log']      = 0;
			arg['asstnum']         = 0;
			arg['asstname']        = 0;
			arg['point_to_log']    = 0;
			arg['score_log']       = 0;

			let usThemArray = ['us', 'them'];
			for (let k=0; k < 2; k++) {
				let usthem = usThemArray[k];

				arg[usthem] = {
					'earned'  : { cnt: 0 },
					'errors'  : { cnt: 0 },
					'faults'  : { cnt: 0 },
					'in_rally': { cnt: 0 },
					'touch'   : { cnt: 0 },
					'edit'    : { cnt: 0 }
				};

				for (let j=0, jLen=EARNED.length; j < jLen; j++) {
					arg[usthem]['earned'][EARNED[j]] = 0;
				}
				for (let j=0, jLen=ERRORS.length; j < jLen; j++) {
					arg[usthem]['errors'][ERRORS[j]] = 0;
				}
				for (let j=0, jLen=FAULTS.length; j < jLen; j++) {
					arg[usthem]['faults'][FAULTS[j]] = 0;
				}
				for (let j=0, jLen=IN_RALLY.length; j < jLen; j++) {
					arg[usthem]['in_rally'][IN_RALLY[j]] = 0;
				}
				for (let j=0, jLen=TOUCH.length; j < jLen; j++) {
					arg[usthem]['touch'][TOUCH[j]] = 0;
				}
				for (let j=0, jLen=EDIT.length; j < jLen; j++) {
					arg[usthem]['edit'][EDIT[j]] = 0;
				}
			}

		};

		// initialize all total values (so we can ++)
		for (let i=0, iLen=grouping.list.length; i < iLen; i++) {
			var id = grouping.list[i];
			initGroupingArraysForId(id);
		}
		initGroupingArraysForAgg();

		var rallyNum = 0;
		var usScore = 0, themScore = 0;

		for (let i=0, iLen = data.length; i < iLen; i++) {

			var row = data[i];

			var logType    = row.log_type;
			var gameId     = row.game_id;

			if (grouping.type === 'game' && !lookup[gameId]) {
				continue;
			}

			logsObj = {};

			var usDelta,
				themDelta,
				serving,
				rotation,
				//desc,
				//point,
				playerId,
				playerName,
				playerTeam,
				actionId,
				codeId,
				pointId,
				asstId,
				asstName,
				serverId,
				setterId,
				score;

			if (row.date) {
				logsObj['timestamp_log'] = (new Date(row.date)).toLocaleTimeString();
			} else {
				logsObj['timestamp_log'] = '';
			}

			if (logType === "timeout") {
				serving    = row.serving;
				rotation   = row.rotation;

				logsObj['action_log'] = row.who + ' ' + row.number;
				logsObj['action_type_log'] = 'timeout';

				logsObj["rotation_log"]   = rotation;
				if (serving === "us") {
					logsObj["stage_log"] = "serve";
				} else if (serving === "them") {
					logsObj["stage_log"] = "receive";
				}
			}

			if (logType === "substitution") {
				serving    = row.serving;
				rotation   = row.rotation;

				logsObj['action_log'] = row.serving + ' ' + row.subbed_in + ' for ' + row.subbed_out;
				logsObj['action_type_log'] = 'substitution';

				logsObj["rotation_log"]   = rotation;
				if (serving === "us") {
					logsObj["stage_log"] = "serve";
				} else if (serving === "them") {
					logsObj["stage_log"] = "receive";
				}
			}

			if (logType === 'adjustment') {
				usDelta    = parseInt(row.us_delta, 10);
				themDelta  = parseInt(row.them_delta, 10);
				serving    = row.serving;
				rotation   = row.rotation;
				//desc       = row.desc;
				//point      = row.point;

				usScore   += usDelta;
				themScore += themDelta;

				// Special case handling table wide display
				logsObj['action_type_log'] = 'Adjustment';

				// Special Adjustment fields, we're using existing fields
				// to pass data.  There is no significance to what fields
				// are being used
				logsObj['action_log'] = row.desc;
				if (usDelta !== 0) {
					if (usDelta > 0) {
						logsObj['stage_log'] = '+' + usDelta;
					} else {
						logsObj['stage_log'] = '+' + usDelta;
					}
				}
				if (themDelta !== 0) {
					if (themDelta > 0) {
						logsObj['rally_log'] = '+' + themDelta;
					} else {
						logsObj['rally_log'] = themDelta;
					}
				}

				score = usScore + ' - ' + themScore;
				logsObj['score_log'] = score;

			} else if (logType === 'entry' || logType === 'partialEntry') {

				rotation   = row.rotation;
				playerTeam = row.player_team;
				playerId   = row.player_num;
				playerName = row.player_name;
				serving    = row.serving;
				actionId   = row.action;
				codeId     = row.code;
				pointId    = row.point;
				asstId     = row.asstnum;
				asstName   = row.asstname;
				setterId   = row.setter;
				serverId   = row.server;

				logsObj["rotation_log"] = rotation;
				agg["rotation_log"] = rotation;

				var previousActionId = i > 0 ? data[i-1].action : null;

				if (actionId === "in_rally" || previousActionId === "earned" || previousActionId === "errors" || previousActionId === "faults") {
					rallyNum += 1;
					logsObj["rally_log"] = rallyNum;
					agg["rally_log"] = rallyNum;
				} else if (actionId === "earned" || actionId === "errors" || actionId === "faults"){
					if (rallyNum === 0) {
						rallyNum += 1;
						logsObj["rally_log"] = rallyNum;
					} else {
						logsObj["rally_log"] = rallyNum;
					}
					agg["rally_log"] = rallyNum;
				}

				if (serving === "us") {
					logsObj["stage_log"] = "serve";
					agg["stage_log"] = "serve";
				} else if (serving === "them") {
					logsObj["stage_log"] = "receive";
					agg["stage_log"] = "receive";
				}

				logsObj["playernum"] = playerId;
				agg["playernum"] = playerId;

				logsObj["playername"] = playerName;
				agg["playername"] = playerName;

				logsObj["action_type_log"] = actionId;
				agg["action_type_log"] = actionId;

				if (codeId in actionsEarned) {
					logsObj["action_log"] = actionsEarned[codeId];
					agg["action_log"] = actionsEarned[codeId];
				} else if (codeId in actionsErrors) {
					logsObj["action_log"] = actionsErrors[codeId];
					agg["action_log"] = actionsErrors[codeId];
				} else if (codeId in actionsFaults) {
					logsObj["action_log"] = actionsFaults[codeId];
					agg["action_log"] = actionsFaults[codeId];
				} else if (codeId in actionsInRally) {
					logsObj["action_log"] = actionsInRally[codeId];
					agg["action_log"] = actionsInRally[codeId];
				} else if (codeId in actionsTouch) {
					logsObj["action_log"] = actionsTouch[codeId];
					agg["action_log"] = actionsTouch[codeId];
				} else {
					logsObj["action_log"] = codeId;
					agg["action_log"] = codeId;
				}

				if (serving === "us") {
					if (serverId !== null) {
						if (playerNames[serverId]) {
							logsObj["servername"] = playerNames[serverId];
							agg["servername"] = playerNames[serverId];
						} else {
							logsObj["servername"] = serverId;
							agg["servername"] = serverId;
						}
					}
				} else {
					logsObj["servername"] = 'Their Player';
					agg["servername"] = 'Their Player';
				}

				// Make sure that it's our point before crediting an assist
				if (playerTeam === "us") {

					// Special handling for old style of tracking assists
					if (codeId === "kill") {
						logsObj["asstnum"] = setterId;
						if (playerNames[setterId]) {
							logsObj["asstname"] = playerNames[setterId];
						} else {
							logsObj["asstname"] = "N/A";
						}
					} else {
						logsObj["asstnum"] = asstId;
						agg["asstnum"] = asstId;
						logsObj["asstname"] = asstName;
						agg["asstname"] = asstName;
					}
				}

				if (logType !== "partialEntry" && (actionId === "earned" || actionId === "errors" || actionId === "faults")) {
					logsObj["point_to_log"] = pointId;
					agg["point_to_log"] = pointId;
				} else if (actionId === "in_rally" || logType === "partialEntry"){
					logsObj["point_to_log"] = "none";
					agg["point_to_log"] = "none";
				}

				if (logType !== "partialEntry" && (actionId === "earned" || actionId === "errors" || actionId === "faults")) {
					if (pointId === "us") {
						usScore++;
						score = usScore + " - " + themScore;
						logsObj["score_log"] = score;
						agg["score_log"] = score;
					} else if (pointId === "them") {
						themScore++;
						score = usScore + " - " + themScore;
						logsObj["score_log"] = score;
						agg["score_log"] = score;
					}
				} else if (actionId === "in_rally" || logType === "partialEntry"){
					score = usScore + " - " + themScore;
					logsObj["score_log"] = score;
					agg["score_log"] = score;
				}

				if (logType === "partialEntry"){
					logsObj["partial"] = true;
				} else {
					logsObj["partial"] = false;
				}

			}
			result.push(logsObj);
		}

		return { byRow: result, agg: agg, hasManualEntries: true };
	};

	// Determines if the index represents the start of a new point
	var isNewPoint = function (data, index) {

		// first, ensure we have a LogEntry
		if (data[index].log_type !== "entry") {
			return false;
		}

		var row, i = index;
		do {
			i--;
			row = data[i];
			if (row) {
				if (row.log_type === "entry") {
					return actionResultsInPoint(row.action);
				}
			} else {
				// there is no previous action
				return true;
			}
		} while (i > 0);

		// if we get here, then it's the first entry
		return true;

	};

	return {
		processStatsIteration  : filterAndAggregateData,
		processPointLogRows    : filterAndAggregatePointLogData,
		init                   : init,
		_test_isNewPoint       : isNewPoint
	};

})();
